import type {
  ColumnDefinitionWithColor,
  ColumnDefinitionWithStaleness,
  PortfolioRow,
  PositionRow,
  LiabilityRow,
  AccountRowObject,
  RpmsBalanceRow,
  PlatformBalanceRow,
  RpmsPositionRow,
  PlatformPositionRow,
  PlatformOpenOrderRow
} from './types'

export const UNTAGGED_ID = 'untagged'
export const UNTAGGED_PRIORITY = 10000
export const UNTAGGED_COLOR = '#FFFFFF'
export const UNTAGGED_NAME = 'Untagged'

export const strategyColumns: Array<ColumnDefinitionWithColor<PositionRow, any>> = [
  {
    id: 'priorityLevel',
    label: 'Pr.',
    columnWidthPercentage: 5,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'name',
    label: 'Strategy',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    indicatorColorId: 'strategyColor'
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'multiplier',
    label: 'Mult.',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'delta',
    label: 'Delta',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'deltaQuantity',
    label: 'Delta Amount',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'marketValue',
    label: 'MV',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  },
  {
    id: 'referenceMarketValue',
    label: '',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  }
]

export const accountColumns: Array<ColumnDefinitionWithStaleness<PortfolioRow, any>> = [
  {
    id: 'name',
    label: 'Account',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'delta',
    label: 'Delta',
    columnWidthPercentage: 5,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'deltaQuantity',
    label: 'Delta Amount',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'marketValue',
    label: 'MV',
    columnWidthPercentage: 18,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  }
]

export interface AccountSubtableColumnDefinition<T> extends ColumnDefinitionWithStaleness<T, any> {
  isDummyColumn: boolean
}

export const rpmsBalancesColumns: Array<AccountSubtableColumnDefinition<RpmsBalanceRow>> = [
  {
    id: 'ticker',
    isDummyColumn: false,
    label: 'Asset',
    columnWidthPercentage: 17,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Balance',
    isDummyColumn: false,
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'quantity',
    isDummyColumn: true,
    label: 'Mult',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'price',
    isDummyColumn: true,
    label: 'Delta',
    columnWidthPercentage: 6,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'price',
    label: 'Price',
    isDummyColumn: false,
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'price',
    isDummyColumn: true,
    label: 'FR',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'marketValue',
    isDummyColumn: false,
    label: 'MV',
    columnWidthPercentage: 30,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  }
]

export const platformBalancesColumns: Array<AccountSubtableColumnDefinition<PlatformBalanceRow>> = [
  {
    id: 'ticker',
    isDummyColumn: false,
    label: 'Asset',
    columnWidthPercentage: 17,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Balance',
    isDummyColumn: false,
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'quantity',
    isDummyColumn: true,
    label: 'Mult',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'price',
    isDummyColumn: true,
    label: 'Delta',
    columnWidthPercentage: 6,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'price',
    label: 'Price',
    isDummyColumn: false,
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'price',
    isDummyColumn: true,
    label: 'FR',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'marketValue',
    isDummyColumn: false,
    label: 'MV',
    columnWidthPercentage: 30,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  }
]

export const rpmsPositionsColumns: Array<AccountSubtableColumnDefinition<RpmsPositionRow>> = [
  {
    id: 'instrumentName',
    isDummyColumn: false,
    label: 'Instrument',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    isDummyColumn: false,
    label: 'Position',
    columnWidthPercentage: 17,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'multiplier',
    isDummyColumn: false,
    label: 'Mult',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'delta',
    isDummyColumn: false,
    label: 'Delta',
    columnWidthPercentage: 6,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'price',
    isDummyColumn: false,
    label: 'Price',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'price',
    isDummyColumn: true,
    label: 'FR',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'marketValue',
    isDummyColumn: false,
    label: 'MV',
    columnWidthPercentage: 30,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  }
]

export const platformPositionsColumns: Array<AccountSubtableColumnDefinition<PlatformPositionRow>> = [
  {
    id: 'instrumentName',
    isDummyColumn: false,
    label: 'Instrument',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    isDummyColumn: false,
    label: 'Position',
    columnWidthPercentage: 17,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'multiplier',
    isDummyColumn: false,
    label: 'Mult',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'delta',
    isDummyColumn: false,
    label: 'Delta',
    columnWidthPercentage: 6,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'price',
    isDummyColumn: false,
    label: 'Price',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'annualizedFundingRate',
    isDummyColumn: false,
    label: 'FR',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'marketValue',
    isDummyColumn: false,
    label: 'MV',
    columnWidthPercentage: 30,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  }
]

export const platformOpenOrdersColumns: Array<AccountSubtableColumnDefinition<PlatformOpenOrderRow>> = [
  {
    id: 'source',
    isDummyColumn: false,
    label: 'Source',
    columnWidthPercentage: 4,
    overflowType: 'tooltip',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'userName',
    isDummyColumn: false,
    label: 'User',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'createdTime',
    isDummyColumn: false,
    label: 'Created',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'ticker',
    isDummyColumn: false,
    label: 'Instrument',
    columnWidthPercentage: 15,
    overflowType: 'tooltip',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'side',
    isDummyColumn: false,
    label: 'Side',
    columnWidthPercentage: 5,
    overflowType: 'wrap',
    sortable: false,
    colored: true,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    signOverrideId: 'orderDirectionSign'
  },
  {
    id: 'quantity',
    isDummyColumn: false,
    label: 'Qty',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'filled',
    isDummyColumn: false,
    label: 'Filled',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'sizeCurrency',
    isDummyColumn: false,
    label: 'Ccy',
    columnWidthPercentage: 6,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'orderType',
    isDummyColumn: false,
    label: 'Type',
    columnWidthPercentage: 6,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    isDummyColumn: false,
    label: 'Price',
    columnWidthPercentage: 6,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'priceCurrency',
    isDummyColumn: false,
    label: 'Ccy',
    columnWidthPercentage: 5,
    overflowType: 'tooltip',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'marketValue',
    isDummyColumn: false,
    label: 'MV',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  }
]

export const modifiedAccountColumns: Array<ColumnDefinitionWithStaleness<AccountRowObject, any>> = [
  {
    id: 'accountDisplay',
    label: 'Account',
    columnWidthPercentage: 30,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'cashValue',
    label: 'Cash',
    columnWidthPercentage: 18,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'oldestCashPriceTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Value calc using price that is'
    }
  },
  {
    id: 'assetsValue',
    label: 'Assets Value',
    columnWidthPercentage: 18,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'oldestAssetPriceTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Value calc using price that is'
    }
  },
  {
    id: 'grossPositionsValue',
    label: 'Gross Notional Positions',
    columnWidthPercentage: 18,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'oldestPositionPriceTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Value calc using price that is'
    }
  }
]

export const counterpartyColumns: Array<ColumnDefinitionWithStaleness<LiabilityRow, any>> = [
  {
    id: 'name',
    label: 'Counterparty',
    columnWidthPercentage: 18,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'multiplier',
    label: 'Mult.',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'effectiveInterestRate',
    label: 'Rate',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'marketValue',
    label: 'MV',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  },
  {
    id: 'referenceMarketValue',
    label: '',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  }
]
