import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Box,
  Grid,
  Stack,
  Table,
  TableContainer,
  TableFooter,
  Typography,
  useTheme
} from '@mui/material'
import {
  type Order,
  TableSkeleton,
  getComparator,
  stableSort,
  Modal
} from '@r40cap/ui'
import {
  type DeribitCrossPortfolioMarginConfig,
  type DeribitCrossPortfolioMarginCurrencyParams,
  type DeribitCrossPortfolioMarginTickerDetails,
  riskApi
} from '@r40cap/pms-sdk'

import HealthScoreDisplay from '../HealthScoreDisplay'
import SortableHeader from '../SortableHeader'
import { collateralColumns, positionColumns } from './columns'
import type { CollateralRow, DummyCollateralObject, DummyPositionObject, PositionRow } from './types'
import AccountTableBody from '../AccountTableBody'
import type { InputType, ShockEffect } from '../types'
import { getModalContent } from '../utils'
import { getDecimals } from '../../tables/rows/utils'
import { getCurrencyPairForTicker, getExpiryTimestampMsForTicker, getPositionTypeForTicker, getPriceCurrencyDisplayForTicker, getSettlementCurrencyForTicker, getStrikeForTicker } from './tickerUtils'
import AccountTableFooterRow from '../AccountTableFooterRow'
import {
  getDeribitMaintenanceMarginRequirement,
  getDeribitDiscountedEquity,
  getInversePutOptionPriceAndDelta,
  getInverseCallOptionPriceAndDelta,
  getLinearPutOptionPriceAndDelta,
  getLinearCallOptionPriceAndDelta,
  MS_PER_YEAR
} from './calculations'
import ShockModalContent from '../ShockModalContent'
import TableErrorBody from '../../../utils/TableErrorBody'

function DeribitXPMCollateralView (props: {
  isFetching: boolean
  balances: Record<string, number>
  indexPrices: Record<string, number>
  currencyToParams: Record<string, DeribitCrossPortfolioMarginCurrencyParams>
  discountedEquity?: number
  editBalance: (currency: string, newBalance: number) => void
  editCurrencyPrice: (currency: string, newPrice: number) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    isFetching,
    balances,
    indexPrices,
    currencyToParams,
    discountedEquity,
    editBalance,
    editCurrencyPrice,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<CollateralRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof CollateralRow>('marketValue')
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)

  const handleRequestSort = (property: keyof CollateralRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    const newRows: CollateralRow[] = Object.entries(balances).map(([currency, balance]) => {
      const params = currencyToParams[currency]
      const price = indexPrices[`${currency}_usd`]
      const [quantDec, pxDec] = getDecimals(price)
      return {
        id: currency,
        ticker: currency.toUpperCase(),
        balance,
        price,
        haircut: 1 - params.haircut,
        priceDecimals: pxDec,
        quantityDecimals: quantDec,
        marketValue: price * balance
      }
    })
    setRows(newRows)
  }, [balances, indexPrices, currencyToParams])

  const visibleRows = useMemo(
    () => {
      return stableSort(rows, (a, b) => {
        return getComparator(order, orderBy)({
          ...a,
          marketValue: Math.abs(a.marketValue)
        },
        {
          ...b,
          marketValue: Math.abs(b.marketValue)
        })
      })
    },
    [order, orderBy, rows]
  )

  const equityStr = discountedEquity === undefined
    ? 'not set'
    : discountedEquity.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof DummyCollateralObject): void {
    if (selectedIds.length === 1) {
      if (property === 'balance') {
        editBalance(selectedIds[0], value)
      } else if (property === 'price') {
        editCurrencyPrice(selectedIds[0], value)
      }
    }
  }

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Collateral
        </Typography>
      </Box>
      <Box sx={{ width: '97%', height: '92%' }}>
        <TableContainer
          sx={{
            backgroundColor: palette.primary.main,
            borderRadius: '5px',
            height: '100%'
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              height: '100%'
            }}
            stickyHeader
          >
            <SortableHeader
              columns={collateralColumns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {
              isError
                ? <TableErrorBody
                    error={error}
                    colsToSpan={collateralColumns.length}
                  />
                : isFetching
                  ? <TableSkeleton numRows={5} columns={collateralColumns}/>
                  : <AccountTableBody
                    rows={visibleRows}
                    handleOpenEdit={
                      (
                        itemId: string,
                        inputType: InputType,
                        label: string,
                        editProperty: keyof DummyCollateralObject
                      ) => {
                        setEditModalOpen(true)
                        setEditModalContent(
                          getModalContent(
                            inputType,
                            label,
                            editProperty,
                            () => { setEditModalOpen(false) },
                            handleSubmission,
                            [itemId],
                            () => {}
                          )
                        )
                      }
                    }
                    columns={collateralColumns}
                  />
            }
            {
              !isFetching && discountedEquity !== undefined && <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0
                }}
              >
                <AccountTableFooterRow
                  name='Discounted Equity'
                  value={`$${equityStr}`}
                  isTopRow
                  numColumns={collateralColumns.length}
                />
              </TableFooter>
            }
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

function DeribitXPMPositionsView (props: {
  isFetching: boolean
  positions: Record<string, number>
  indexPrices: Record<string, number>
  tickerToDetails: Record<string, DeribitCrossPortfolioMarginTickerDetails>
  maintnenaceMarginRequirement?: number
  editPositionSize: (ticker: string, newSize: number) => void
  editPositionPrice: (ticker: string, newSize: number) => void
  editOptionVol: (ticker: string, newVol: number) => void
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    isFetching,
    positions,
    indexPrices,
    tickerToDetails,
    maintnenaceMarginRequirement,
    editPositionSize,
    editPositionPrice,
    editOptionVol,
    isError,
    error
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<PositionRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PositionRow>('marketValue')
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)

  const handleRequestSort = (property: keyof PositionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    const newRows: PositionRow[] = Object.entries(positions).map(([ticker, psn]) => {
      const details = tickerToDetails[ticker]
      const price = details.markPrice
      const [quantDec, pxDec] = getDecimals(price)
      const priceCurrency = getPriceCurrencyDisplayForTicker(ticker)
      const indexPrice = priceCurrency === 'USD'
        ? 1.0
        : indexPrices[`${priceCurrency.toLowerCase()}_usd`]
      return {
        id: ticker,
        ticker,
        quantity: psn,
        price,
        priceCurrency,
        priceDecimals: pxDec,
        quantityDecimals: quantDec,
        marketValue: psn * price * indexPrice,
        annualizedFundingRatePct: undefined,
        delta: details.delta,
        impliedVolatilityPct: details.iv === null || details.iv === undefined ? undefined : details.iv * 100
      }
    })
    setRows(newRows)
  }, [positions, tickerToDetails])

  const visibleRows = useMemo(
    () => {
      return stableSort(rows, (a, b) => {
        return getComparator(order, orderBy)({
          ...a,
          marketValue: Math.abs(a.marketValue)
        },
        {
          ...b,
          marketValue: Math.abs(b.marketValue)
        })
      })
    },
    [order, orderBy, rows]
  )

  const grossNotionalStr = visibleRows.reduce((acc, row) => acc + Math.abs(row.marketValue), 0).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const mmrStr = maintnenaceMarginRequirement === undefined
    ? 'not set'
    : maintnenaceMarginRequirement.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof DummyPositionObject): void {
    if (selectedIds.length === 1) {
      if (property === 'quantity') {
        editPositionSize(selectedIds[0], value)
      } else if (property === 'price') {
        const shouldAllow = getPositionTypeForTicker(selectedIds[0]) !== 'option'
        if (shouldAllow) {
          editPositionPrice(selectedIds[0], value)
        }
      } else if (property === 'iv') {
        const shouldAllow = getPositionTypeForTicker(selectedIds[0]) === 'option'
        if (shouldAllow) {
          editOptionVol(selectedIds[0], value)
        }
      }
    }
  }

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Positions
        </Typography>
      </Box>
      <Box sx={{ width: '97%', height: '92%' }}>
        <TableContainer
          sx={{
            backgroundColor: palette.primary.main,
            borderRadius: '5px',
            height: '100%'
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              height: '100%'
            }}
            stickyHeader
          >
            <SortableHeader
              columns={positionColumns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {
              isError
                ? <TableErrorBody
                    error={error}
                    colsToSpan={positionColumns.length}
                  />
                : isFetching
                  ? <TableSkeleton numRows={5} columns={positionColumns}/>
                  : <AccountTableBody
                    rows={visibleRows}
                    handleOpenEdit={
                      (
                        itemId: string,
                        inputType: InputType,
                        label: string,
                        editProperty: keyof DummyPositionObject
                      ) => {
                        const shouldOpen = editProperty === 'price'
                          ? getPositionTypeForTicker(itemId) !== 'option'
                          : editProperty === 'iv'
                            ? getPositionTypeForTicker(itemId) === 'option'
                            : true
                        if (shouldOpen) {
                          setEditModalOpen(true)
                          setEditModalContent(
                            getModalContent(
                              inputType,
                              label,
                              editProperty,
                              () => { setEditModalOpen(false) },
                              handleSubmission,
                              [itemId],
                              () => {}
                            )
                          )
                        }
                      }
                    }
                    columns={positionColumns}
                  />
            }
            {
              !isFetching && <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0
                }}
              >
                <AccountTableFooterRow
                  name='Gross Notional'
                  value={`$${grossNotionalStr}`}
                  isTopRow
                  numColumns={positionColumns.length}
                />
                {
                  maintnenaceMarginRequirement !== undefined && <AccountTableFooterRow
                    name='MMR'
                    value={`$${mmrStr}`}
                    isTopRow={false}
                    numColumns={positionColumns.length}
                  />
                }
              </TableFooter>
            }
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

function DeribitXPMRiskContent (props: {
  hasEdited: boolean
  setHasEdited: (hasEdited: boolean) => void
  refreshSignal: boolean
  resetSignal: boolean
  shockModalOpen: boolean
  setShockModalOpen: (shockModalOpen: boolean) => void
}): React.JSX.Element {
  const { accountId } = useParams()
  const {
    hasEdited,
    setHasEdited,
    refreshSignal,
    resetSignal,
    shockModalOpen,
    setShockModalOpen
  } = props
  const [maintenanceMarginRequirement, setMaintenanceMarginRequirement] = useState<number | null>(null)
  const [discountedEquity, setDiscountedEquity] = useState<number | null>(null)
  const [editedConfig, setEditedConfig] = React.useState<DeribitCrossPortfolioMarginConfig | null>(null)

  const {
    data,
    refetch,
    isFetching,
    isError,
    error
  } = riskApi.useGetDeribitCrossPortfolioMarginConfigQuery({ accountId: accountId ?? '' })

  useEffect(() => {
    if (editedConfig === null) {
      setMaintenanceMarginRequirement(null)
      setDiscountedEquity(null)
    } else {
      const mmr = getDeribitMaintenanceMarginRequirement(editedConfig)
      const eq = getDeribitDiscountedEquity(editedConfig)
      setMaintenanceMarginRequirement(mmr)
      setDiscountedEquity(eq)
    }
  }, [editedConfig])

  useEffect(() => {
    refetch().catch((err) => { console.error(err) })
  }, [refreshSignal])

  useEffect(() => {
    setEditedConfig(data?.data ?? null)
    setHasEdited(false)
  }, [resetSignal])

  useEffect(() => {
    setEditedConfig(data?.data ?? null)
    setHasEdited(false)
  }, [data])

  function editBalance (currency: string, newBalance: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const newConfig = JSON.parse(JSON.stringify(editedConfig)) as DeribitCrossPortfolioMarginConfig
    newConfig.portfolio.currency[currency] = newBalance
    setEditedConfig(newConfig)
    setHasEdited(true)
  }

  function editPositionSize (ticker: string, newSize: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const newConfig = JSON.parse(JSON.stringify(editedConfig)) as DeribitCrossPortfolioMarginConfig
    newConfig.portfolio.position[ticker] = newSize
    setEditedConfig(newConfig)
    setHasEdited(true)
  }

  function getCurrentCurrencyPrice (currency: string): number | undefined {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return undefined
    }
    const usdPair = `${currency}_usd`
    const usdcPair = `${currency}_usdc`
    const usdtPair = `${currency}_usdt`
    if (usdPair in editedConfig.indexPrice) {
      return editedConfig.indexPrice[usdPair]
    } else if (usdcPair in editedConfig.indexPrice && 'usdc_usd' in editedConfig.indexPrice) {
      return editedConfig.indexPrice[usdcPair] * editedConfig.indexPrice.usdc_usd
    } else if (usdtPair in editedConfig.indexPrice && 'usdt_usd' in editedConfig.indexPrice) {
      return editedConfig.indexPrice[usdtPair] * editedConfig.indexPrice.usdt_usd
    }
    return undefined
  }

  function getMarkPrice (ticker: string): number | undefined {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return undefined
    }
    if (ticker in editedConfig.ticker) {
      return editedConfig.ticker[ticker].markPrice
    } else {
      console.log('No Mark Price for %s', ticker)
      return undefined
    }
  }

  function editCurrencyPrice (currency: string, newPrice: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const currentPrice = getCurrentCurrencyPrice(currency)
    if (currentPrice === undefined) {
      console.log('No Current Price Available for %s', currency)
      return
    }
    const priceFactor = newPrice / currentPrice
    const editableConfig = JSON.parse(JSON.stringify(editedConfig)) as DeribitCrossPortfolioMarginConfig
    const newConfig = modifyCurrencyPriceByFactor(editableConfig, currency, priceFactor)
    setEditedConfig(newConfig)
    setHasEdited(true)
  }

  function editPositionPrice (ticker: string, newPrice: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const currentPrice = getMarkPrice(ticker)
    if (currentPrice === undefined) {
      console.log('No Mark Price Available for %s', ticker)
      return
    }
    const priceFactor = newPrice / currentPrice
    const currencyPair = getCurrencyPairForTicker(ticker)
    const base = currencyPair.split('_', 2)[0]
    const editableConfig = JSON.parse(JSON.stringify(editedConfig)) as DeribitCrossPortfolioMarginConfig
    const newConfig = modifyCurrencyPriceByFactor(editableConfig, base, priceFactor)
    setEditedConfig(newConfig)
    setHasEdited(true)
  }

  function handlePriceShock (effects: ShockEffect[]): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    let editableConfig = JSON.parse(JSON.stringify(editedConfig)) as DeribitCrossPortfolioMarginConfig
    for (const shockEffect of effects) {
      editableConfig = modifyCurrencyPriceByFactor(editableConfig, shockEffect.currencyId.toLowerCase(), shockEffect.priceFactor)
    }
    setEditedConfig(editableConfig)
    setHasEdited(true)
  }

  function editOptionVol (ticker: string, newVol: number): void {
    if (editedConfig === null) {
      console.log('Edited config is null')
      return
    }
    const newConfig = JSON.parse(JSON.stringify(editedConfig)) as DeribitCrossPortfolioMarginConfig
    const underlyingPrice = newConfig.ticker[ticker].underlyingPrice
    if (underlyingPrice === undefined || underlyingPrice === null) {
      console.error('Option underlying price not defined')
      return
    }
    const settlementCurrencyLower = getSettlementCurrencyForTicker(ticker).toLowerCase()
    const isInverse = settlementCurrencyLower === 'btc' || settlementCurrencyLower === 'eth'
    const isPut = ticker.endsWith('-P')
    const strike = getStrikeForTicker(ticker)
    const expiryMs = getExpiryTimestampMsForTicker(ticker)
    const yearsToExpiry = (expiryMs - newConfig.modelParams.general.timestamp) / MS_PER_YEAR
    const [newMarkPrice, newDelta] = isInverse
      ? isPut
        ? getInversePutOptionPriceAndDelta(strike, underlyingPrice, newVol, yearsToExpiry)
        : getInverseCallOptionPriceAndDelta(strike, underlyingPrice, newVol, yearsToExpiry)
      : isPut
        ? getLinearPutOptionPriceAndDelta(strike, underlyingPrice, newVol, yearsToExpiry)
        : getLinearCallOptionPriceAndDelta(strike, underlyingPrice, newVol, yearsToExpiry)
    const pnlInKind = (newMarkPrice - newConfig.ticker[ticker].markPrice) * newConfig.portfolio.position[ticker]
    newConfig.ticker[ticker] = {
      ...newConfig.ticker[ticker],
      iv: newVol,
      delta: newDelta,
      markPrice: newMarkPrice
    }
    if (settlementCurrencyLower in newConfig.portfolio.currency) {
      newConfig.portfolio.currency[settlementCurrencyLower] += pnlInKind
    } else {
      newConfig.portfolio.currency[settlementCurrencyLower] = pnlInKind
    }
    setEditedConfig(newConfig)
    setHasEdited(true)
  }

  function modifyCurrencyPriceByFactor (configToEdit: DeribitCrossPortfolioMarginConfig, currency: string, factor: number): DeribitCrossPortfolioMarginConfig {
    for (const currencyPair in configToEdit.indexPrice) {
      const [base, quote] = currencyPair.split('_', 2)
      if (base === currency) {
        configToEdit.indexPrice[currencyPair] *= factor
      }
      if (quote === currency) {
        configToEdit.indexPrice[currencyPair] /= factor
      }
    }
    const currencyToBalanceChange: Record<string, number> = {}
    for (const ticker in configToEdit.ticker) {
      const currencyPair = getCurrencyPairForTicker(ticker)
      const [base, quote] = currencyPair.split('_', 2)
      if (base !== currency && quote !== currency) {
        continue
      }
      const positionType = getPositionTypeForTicker(ticker)
      if (positionType === 'future' || positionType === 'perpetual') {
        const settlementCurrencyLower = getSettlementCurrencyForTicker(ticker).toLowerCase()
        const settlementCurrencyPrice = configToEdit.indexPrice[`${settlementCurrencyLower}_usd`]
        let newMarkPrice = configToEdit.ticker[ticker].markPrice
        let newIndexPrice = configToEdit.ticker[ticker].indexPrice
        if (base === currency) {
          newMarkPrice *= factor
          newIndexPrice *= factor
        }
        if (quote === currency) {
          newMarkPrice /= factor
          newIndexPrice /= factor
        }
        const pnlUsd = (newMarkPrice - configToEdit.ticker[ticker].markPrice) * configToEdit.portfolio.position[ticker]
        const pnlInKind = pnlUsd / settlementCurrencyPrice
        configToEdit.ticker[ticker].markPrice = newMarkPrice
        configToEdit.ticker[ticker].indexPrice = newIndexPrice
        if (settlementCurrencyLower in currencyToBalanceChange) {
          currencyToBalanceChange[settlementCurrencyLower] += pnlInKind
        } else {
          currencyToBalanceChange[settlementCurrencyLower] = pnlInKind
        }
      } else {
        if (configToEdit.ticker[ticker].iv === null || configToEdit.ticker[ticker].iv === undefined) {
          console.error('No IV for %s', ticker)
          continue
        }
        if (configToEdit.ticker[ticker].underlyingPrice === null || configToEdit.ticker[ticker].underlyingPrice === undefined) {
          console.error('No Underlying Price for %s', ticker)
          continue
        }
        const settlementCurrencyLower = getSettlementCurrencyForTicker(ticker).toLowerCase()
        let newUnderlyingPrice = configToEdit.ticker[ticker].underlyingPrice
        let newIndexPrice = configToEdit.ticker[ticker].indexPrice
        if (base === currency) {
          newUnderlyingPrice *= factor
          newIndexPrice *= factor
        }
        if (quote === currency) {
          newUnderlyingPrice /= factor
          newIndexPrice /= factor
        }
        const isInverse = settlementCurrencyLower === 'btc' || settlementCurrencyLower === 'eth'
        const isPut = ticker.endsWith('-P')
        const strike = getStrikeForTicker(ticker)
        const expiryMs = getExpiryTimestampMsForTicker(ticker)
        const yearsToExpiry = (expiryMs - configToEdit.modelParams.general.timestamp) / MS_PER_YEAR
        const [newMarkPrice, newDelta] = isInverse
          ? isPut
            ? getInversePutOptionPriceAndDelta(strike, newUnderlyingPrice, configToEdit.ticker[ticker].iv, yearsToExpiry)
            : getInverseCallOptionPriceAndDelta(strike, newUnderlyingPrice, configToEdit.ticker[ticker].iv, yearsToExpiry)
          : isPut
            ? getLinearPutOptionPriceAndDelta(strike, newUnderlyingPrice, configToEdit.ticker[ticker].iv, yearsToExpiry)
            : getLinearCallOptionPriceAndDelta(strike, newUnderlyingPrice, configToEdit.ticker[ticker].iv, yearsToExpiry)
        const pnlInKind = (newMarkPrice - configToEdit.ticker[ticker].markPrice) * configToEdit.portfolio.position[ticker]
        configToEdit.ticker[ticker].markPrice = newMarkPrice
        configToEdit.ticker[ticker].indexPrice = newIndexPrice
        configToEdit.ticker[ticker].underlyingPrice = newUnderlyingPrice
        configToEdit.ticker[ticker].delta = newDelta
        if (settlementCurrencyLower in currencyToBalanceChange) {
          currencyToBalanceChange[settlementCurrencyLower] += pnlInKind
        } else {
          currencyToBalanceChange[settlementCurrencyLower] = pnlInKind
        }
      }
    }
    for (const currency in currencyToBalanceChange) {
      if (currency in configToEdit.portfolio.currency) {
        configToEdit.portfolio.currency[currency] += currencyToBalanceChange[currency]
      } else {
        configToEdit.portfolio.currency[currency] = currencyToBalanceChange[currency]
      }
    }
    return configToEdit
  }

  return (
    <Stack
      sx={{ height: '100%', width: '100%', alignItems: 'center' }}
      spacing={1}
    >
      <Box sx={{ width: '30%', height: '20vh', minWidth: '200px' }}>
        <HealthScoreDisplay
          accountId={accountId ?? ''}
          score={discountedEquity !== null && maintenanceMarginRequirement !== null ? discountedEquity / maintenanceMarginRequirement : null}
          isFetching={isFetching}
          isEdited={hasEdited}
          isError={isError}
        />
      </Box>
      <Grid
        container
        overflow={'scroll'}
        sx={{ height: 'calc(78vh - 7% - 90px - 10px)' }}
      >
        <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
          <DeribitXPMCollateralView
            isFetching={isFetching}
            balances={editedConfig?.portfolio.currency ?? {}}
            indexPrices={editedConfig?.indexPrice ?? {}}
            currencyToParams={editedConfig?.modelParams.currency ?? {}}
            discountedEquity={discountedEquity ?? undefined}
            editBalance={editBalance}
            editCurrencyPrice={editCurrencyPrice}
            isError={isError}
            error={error}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
          <DeribitXPMPositionsView
            isFetching={isFetching}
            positions={editedConfig?.portfolio.position ?? {}}
            indexPrices={editedConfig?.indexPrice ?? {}}
            tickerToDetails={editedConfig?.ticker ?? {}}
            maintnenaceMarginRequirement={maintenanceMarginRequirement ?? undefined}
            editPositionSize={editPositionSize}
            editPositionPrice={editPositionPrice}
            editOptionVol={editOptionVol}
            isError={isError}
            error={error}
          />
        </Grid>
      </Grid>
      <Modal
        handleClose={() => { setShockModalOpen(false) }}
        open={shockModalOpen}
      >
        <ShockModalContent
          processShock={handlePriceShock}
          closeModal={() => { setShockModalOpen(false) }}
        />
      </Modal>
    </Stack>
  )
}

export default DeribitXPMRiskContent
