import React, { useMemo } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  CircularProgress,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { HeaderCell, ValueCell, stableSort, getComparator } from '@r40cap/ui'
import type { OpenOrder } from '@r40cap/pms-sdk'

import type { PlatformOpenOrderRow } from '../types'
import { platformOpenOrdersColumns } from '../constants'
import { getDecimals } from './utils'
import TableErrorBody from '../../../utils/TableErrorBody'

function PlatformOpenOrdersSubTableRow (props: {
  containedRows: OpenOrder[]
  currencyPriceMap: Map<string, number>
  isFetching: boolean
  colsToSpan: number
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    colsToSpan,
    isFetching,
    currencyPriceMap,
    containedRows,
    isError,
    error
  } = props

  function getPxUsd (price: number, priceQuoteCurrencyId: string, multiplier: number): number {
    if (priceQuoteCurrencyId === 'USD') {
      return price * multiplier
    } else {
      const quoteCurrencyPrice = currencyPriceMap.get(priceQuoteCurrencyId)
      return quoteCurrencyPrice !== undefined ? price * quoteCurrencyPrice * multiplier : 0
    }
  }

  const visibleRows = useMemo(
    () => {
      return stableSort(containedRows, (a, b) => {
        const aSize = a.size.sizeCurrencyId === a.price.priceQuoteCurrencyId
          ? getPxUsd(a.size.orderSize, a.size.sizeCurrencyId, a.multiplier)
          : a.size.sizeCurrencyId === a.price.priceBaseCurrencyId
            ? getPxUsd(a.size.orderSize * a.price.price, a.price.priceQuoteCurrencyId, a.multiplier)
            : 0
        const bSize = b.size.sizeCurrencyId === b.price.priceQuoteCurrencyId
          ? getPxUsd(b.size.orderSize, b.size.sizeCurrencyId, b.multiplier)
          : b.size.sizeCurrencyId === b.price.priceBaseCurrencyId
            ? getPxUsd(b.size.orderSize * b.price.price, b.price.priceQuoteCurrencyId, b.multiplier)
            : 0
        return getComparator('desc', 'marketValue')({
          marketValue: aSize
        },
        {
          marketValue: bSize
        })
      })
    },
    [containedRows, currencyPriceMap]
  )

  return (
    <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
      {
        isFetching
          ? <>
            <TableCell colSpan={1} sx={{ alignContent: 'start' }}>
              <Typography variant='h6'>Open Orders</Typography>
            </TableCell>
            <TableCell align='center' colSpan={colsToSpan - 1}>
              <CircularProgress style={{ color: palette.accent.main }}/>
            </TableCell>
          </>
          : (visibleRows.length === 0 && !isError)
              ? <TableCell colSpan={colsToSpan} align='center' sx={{ alignContent: 'center' }}>
              <Typography variant='h6'>
                No Open Orders
              </Typography>
            </TableCell>
              : <>
              <TableCell colSpan={1} sx={{ alignContent: 'start' }}>
                <Typography variant='h6'>Open Orders</Typography>
              </TableCell>
              <TableCell colSpan={colsToSpan - 1}>
                <Table size='small' sx={{ borderRadius: '3px' }}>
                  <TableRow>
                    {
                      platformOpenOrdersColumns.map((column, idx) => (
                        <HeaderCell<PlatformOpenOrderRow, any>
                          column={column}
                          isActiveSort={false}
                          order={'asc'}
                          sortHandler={(): void => {}}
                          key={column.id}
                          backgroundColor={palette.background.default}
                          textColor={palette.tableHeaderText.main}
                          activeTextColor={palette.accent.main}
                          dense
                        />
                      ))
                    }
                  </TableRow>
                  {
                    isError
                      ? <TableErrorBody
                          colsToSpan={platformOpenOrdersColumns.length}
                          error={error}
                        />
                      : visibleRows.map((row) => (
                        <TableRow key={row.id}>
                          {
                            platformOpenOrdersColumns.map((column, index) => {
                              if (column.isDummyColumn as boolean) {
                                return <TableCell key={column.id} />
                              } else {
                                const rowSizeUns = row.size.sizeCurrencyId === row.price.priceQuoteCurrencyId
                                  ? getPxUsd(row.size.orderSize, row.size.sizeCurrencyId, row.multiplier)
                                  : row.size.sizeCurrencyId === row.price.priceBaseCurrencyId
                                    ? getPxUsd(row.size.orderSize * row.price.price, row.price.priceQuoteCurrencyId, row.multiplier)
                                    : 0
                                const rowPrice = getPxUsd(row.price.price, row.price.priceQuoteCurrencyId, 1)
                                const [quantityDecimals, priceDecimals] = getDecimals(rowPrice)
                                const orderSign = row.size.side === 'buy' ? 1 : -1
                                const rowSize = rowSizeUns * orderSign
                                return <ValueCell<PlatformOpenOrderRow, any>
                                  column={column}
                                  item={{
                                    ticker: row.ticker,
                                    side: row.size.side.toUpperCase(),
                                    quantity: row.size.orderSize,
                                    filled: row.size.filledSize,
                                    orderDirectionSign: orderSign,
                                    sizeCurrency: row.size.sizeCurrencyId,
                                    price: row.price.price,
                                    priceCurrency: row.price.priceQuoteCurrencyId,
                                    marketValue: rowSize,
                                    quantityDecimals,
                                    priceDecimals,
                                    source: row.source,
                                    userName: row.userName ?? '',
                                    orderType: row.orderType,
                                    createdTime: row.createdTime
                                  }}
                                  key={column.id}
                                  defaultTextColor={palette.tableBodyText.main}
                                  redTextColor='red'
                                  greenTextColor='green'
                                />
                              }
                            })
                          }
                        </TableRow>
                      ))
                  }
                </Table>
              </TableCell>
            </>
      }
    </TableRow>
  )
}

export default PlatformOpenOrdersSubTableRow
