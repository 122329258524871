import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  CircularProgress,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { HeaderCell, ValueCell, stableSort, getComparator } from '@r40cap/ui'
import { type CollateralItem } from '@r40cap/pms-sdk'

import type { PlatformBalanceRow } from '../types'
import { platformBalancesColumns } from '../constants'
import { getDecimals } from './utils'
import TableErrorBody from '../../../utils/TableErrorBody'

function PlatformBalancesSubTableRow (props: {
  containedRows: CollateralItem[]
  isFetching: boolean
  colsToSpan: number
  includeSmallAmounts: boolean
  smallThreshold: number
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  dayjs.extend(minMax)
  dayjs.extend(utc)
  const { palette } = useTheme()
  const {
    colsToSpan,
    isFetching,
    containedRows,
    includeSmallAmounts,
    smallThreshold,
    isError,
    error
  } = props

  const visibleRows = useMemo(
    () => {
      const filteredForSmallRows = includeSmallAmounts
        ? containedRows
        : containedRows.filter((row) => row.price === undefined || Math.abs(row.balance * row.price * row.multiplier) > smallThreshold)
      return stableSort(filteredForSmallRows, (a, b) => {
        return getComparator('desc', 'marketValue')({
          marketValue: a.price === undefined ? undefined : a.balance * a.price * a.multiplier
        },
        {
          marketValue: b.price === undefined ? undefined : b.balance * b.price * b.multiplier
        })
      })
    },
    [containedRows, includeSmallAmounts, smallThreshold]
  )

  return (
    <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
      {
        isFetching
          ? <>
            <TableCell colSpan={1} sx={{ alignContent: 'start' }}>
              <Typography variant='h6'>Balances</Typography>
            </TableCell>
            <TableCell align='center' colSpan={colsToSpan - 1}>
              <CircularProgress style={{ color: palette.accent.main }}/>
            </TableCell>
          </>
          : (visibleRows.length === 0 && !isError)
              ? <TableCell colSpan={colsToSpan} align='center' sx={{ alignContent: 'center' }}>
                <Typography variant='h6'>
                  {
                    containedRows.length === 0
                      ? 'No Balances'
                      : `Hiding ${containedRows.length} Small ${containedRows.length === 1 ? 'Balance' : 'Balances'}`
                  }
                </Typography>
              </TableCell>
              : <>
                <TableCell colSpan={1} sx={{ alignContent: 'start' }}>
                  <Typography variant='h6'>Balances</Typography>
                </TableCell>
                <TableCell colSpan={colsToSpan - 1}>
                  <Table size='small' sx={{ borderRadius: '3px' }}>
                    <TableRow>
                      {
                        platformBalancesColumns.map((column, idx) => (
                          <HeaderCell<PlatformBalanceRow, any>
                            column={column}
                            isActiveSort={false}
                            order={'asc'}
                            sortHandler={(): void => {}}
                            key={column.id}
                            backgroundColor={palette.background.default}
                            textColor={palette.tableHeaderText.main}
                            activeTextColor={palette.accent.main}
                            dense
                          />
                        ))
                      }
                    </TableRow>
                    {
                      isError
                        ? <TableErrorBody
                            colsToSpan={platformBalancesColumns.length}
                            error={error}
                          />
                        : visibleRows.map((row) => (
                          <TableRow key={row.id}>
                            {
                              platformBalancesColumns.map((column, index) => {
                                if (column.isDummyColumn as boolean) {
                                  return <TableCell key={column.id} />
                                } else {
                                  const [quantityDecimals, priceDecimals] = getDecimals(row.price)
                                  return <ValueCell<PlatformBalanceRow, any>
                                    column={column}
                                    item={{
                                      ticker: row.ticker,
                                      quantity: row.balance,
                                      price: row.price,
                                      marketValue: row.balance * row.price * row.multiplier,
                                      quantityDecimals,
                                      priceDecimals
                                    }}
                                    key={column.id}
                                    defaultTextColor={palette.tableBodyText.main}
                                    redTextColor='red'
                                    greenTextColor='green'
                                  />
                                }
                              })
                            }
                        </TableRow>
                        ))
                    }
                </Table>
              </TableCell>
            </>
      }
    </TableRow>
  )
}

export default PlatformBalancesSubTableRow
