import React, { useState } from 'react'
import { type Dayjs } from 'dayjs'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  useTheme
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import AddIcon from '@mui/icons-material/Add'
import { DateTimePicker } from '@mui/x-date-pickers'
import { BooleanInput, Modal } from '@r40cap/ui'
import { getBlotterExport } from '@r40cap/pms-sdk'
import { useAuth } from '@r40cap/auth'
import type { FilterPiece } from '../../common/types'
import { BaseFxFilterContent, DeskFilterContent, FilterDescription, StrategyFilterContent } from '../filters'

interface ResponseStatus {
  state: 'pending' | 'success' | 'failure'
  message: string
}

function FilterView (props: {
  description: string
  selected: FilterPiece[]
  setSelected: (pieces: FilterPiece[]) => void
  openModal: () => void
  isInclusive: boolean
  setIsInclusive?: (isInclusive: boolean) => void
}): React.JSX.Element {
  const {
    description,
    selected,
    setSelected,
    openModal,
    isInclusive,
    setIsInclusive
  } = props
  const { palette } = useTheme()

  return (
    <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
      <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
          <Typography sx={{ color: palette.tableBodyText.main }}>{isInclusive ? 'Included' : 'Excluded'} {description}</Typography>
          <IconButton
            onClick={openModal}
            sx={{ color: palette.tableBodyText.main }}
          >
            <AddIcon fontSize='small'/>
          </IconButton>
        </Stack>
        <Box>
          {
            setIsInclusive !== undefined && <BooleanInput
              value={isInclusive}
              setValue={setIsInclusive}
              label='Inclusive?'
              boxColor={palette.accent.main}
              textColor={palette.tableBodyText.main}
            />
          }
        </Box>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        {
          selected.length > 0
            ? <FilterDescription
              name={description}
              values={selected}
              clearFunction={() => { setSelected([]) }}
            />
            : <Typography sx={{ color: palette.tableBodyText.main, opacity: 0.5 }}>No {description} selected</Typography>
        }
      </Stack>
    </Stack>
  )
}

export function AggregationTypeSelection (props: {
  aggregationType: 'none' | 'day' | 'transaction'
  setAggregationType: (aggregationType: 'none' | 'day' | 'transaction') => void
}): React.JSX.Element {
  const { aggregationType, setAggregationType } = props

  const handleAggregationTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ): void => {
    if (newValue === 'none') {
      setAggregationType(newValue)
    } else if (newValue === 'day') {
      setAggregationType(newValue)
    } else if (newValue === 'transaction') {
      setAggregationType(newValue)
    }
  }

  return (
    <ToggleButtonGroup
      value={aggregationType}
      exclusive
      onChange={handleAggregationTypeChange}
      style={{
        width: '100%'
      }}
    >
      <ToggleButton
        value='none'
        style={{ width: '33%' }}
      >
        <Typography>No Agg</Typography>
      </ToggleButton>
      <ToggleButton
        value='day'
        style={{ width: '34%' }}
      >
        <Typography>Day</Typography>
      </ToggleButton>
      <ToggleButton
        value='transaction'
        style={{ width: '33%' }}
      >
        <Typography>Txn</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

function EntryContent (props: {
  startDownload: (
    aggregationType: 'none' | 'day' | 'transaction',
    includeFees: boolean,
    endTime?: Dayjs,
    startTime?: Dayjs,
    includedDesks?: readonly string[],
    includedStrategies?: readonly string[],
    includedBaseFxs?: readonly string[],
    excludedBaseFxs?: readonly string[]
  ) => void
}): React.JSX.Element {
  const {
    startDownload
  } = props
  const { palette } = useTheme()
  const authConext = useAuth()
  const restDesk = authConext.restrictedDeskId ?? undefined
  const [modalContent, setModalContent] = useState<React.JSX.Element | null>(null)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const [includeFees, setIncludeFees] = useState<boolean>(false)
  const [basesAreIncluded, setBasesAreIncluded] = useState<boolean>(false)
  const [aggregationType, setAggregationType] = useState<'none' | 'day' | 'transaction'>('none')
  const [startTime, setStartTime] = useState<Dayjs | null>(null)
  const [endTime, setEndTime] = useState<Dayjs | null>(null)
  const [desks, setDesks] = useState<FilterPiece[]>([])
  const [strategies, setStrategies] = useState<FilterPiece[]>([])
  const [baseFxs, setBaseFxs] = useState<FilterPiece[]>([])

  function executeDownload (): void {
    startDownload(
      aggregationType,
      includeFees,
      endTime ?? undefined,
      startTime ?? undefined,
      desks.length === 0 ? undefined : desks.map((desk) => desk.id),
      strategies.length === 0 ? undefined : strategies.map((strategy) => strategy.id),
      !basesAreIncluded || baseFxs.length === 0 ? undefined : baseFxs.map((baseFx) => baseFx.id),
      basesAreIncluded || baseFxs.length === 0 ? undefined : baseFxs.map((baseFx) => baseFx.id)
    )
  }

  return (
    <Box
      sx={{ width: '30vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', alignItems: 'center', justifyItems: 'start' }}>
          <Typography sx={{ width: '30%', color: palette.tableBodyText.main }}>Aggregate By</Typography>
          <AggregationTypeSelection
            aggregationType={aggregationType}
            setAggregationType={setAggregationType}
          />
        </Stack>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', alignItems: 'center', justifyItems: 'start' }}>
          <Typography sx={{ color: palette.tableBodyText.main }}>Start Time</Typography>
          <DateTimePicker
            value={startTime}
            format='YYYY-MM-DD HH:mm'
            onChange={setStartTime}
            closeOnSelect={false}
            ampm={false}
            timeSteps={{ hours: 1, minutes: 1 }}
          />
        </Stack>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', alignItems: 'center', justifyItems: 'start' }}>
          <Typography sx={{ color: palette.tableBodyText.main }}>End Time</Typography>
          <DateTimePicker
            value={endTime}
            format='YYYY-MM-DD HH:mm'
            onChange={setEndTime}
            closeOnSelect={false}
            ampm={false}
            timeSteps={{ hours: 1, minutes: 1 }}
          />
        </Stack>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', alignItems: 'center', justifyItems: 'start' }}>
          <BooleanInput
            value={includeFees}
            setValue={setIncludeFees}
            label='Include Fees?'
            boxColor={palette.accent.main}
            textColor={palette.tableBodyText.main}
          />
        </Stack>
        {
          restDesk === undefined && <FilterView
            description='Desks'
            selected={desks}
            setSelected={setDesks}
            openModal={() => {
              setModalContent(
                <DeskFilterContent
                  submitFunction={(value: FilterPiece) => {
                    if (!desks.some((desk) => desk.id === value.id)) {
                      setDesks([...desks, value])
                    }
                    setModalContent(null)
                    setModalIsOpen(false)
                  }}
                />
              )
              setModalIsOpen(true)
            }}
            isInclusive
          />
        }
        <FilterView
          description='Strategies'
          selected={strategies}
          setSelected={setStrategies}
          openModal={() => {
            setModalContent(
              <StrategyFilterContent
                submitFunction={(value: FilterPiece) => {
                  if (!strategies.some((strategy) => strategy.id === value.id)) {
                    setStrategies([...strategies, value])
                  }
                  setModalContent(null)
                  setModalIsOpen(false)
                }}
              />
            )
            setModalIsOpen(true)
          }}
          isInclusive
        />
        <FilterView
          description='Base Fxs'
          selected={baseFxs}
          setSelected={setBaseFxs}
          openModal={() => {
            setModalContent(
              <BaseFxFilterContent
                submitFunction={(value: FilterPiece) => {
                  if (!baseFxs.some((baseFx) => baseFx.id === value.id)) {
                    setBaseFxs([...baseFxs, value])
                  }
                  setModalContent(null)
                  setModalIsOpen(false)
                }}
              />
            )
            setModalIsOpen(true)
          }}
          isInclusive={basesAreIncluded}
          setIsInclusive={setBasesAreIncluded}
        />
        <Button
          variant='contained'
          onClick={executeDownload}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.primary.main,
            width: '50%'
          }}
        >
          Download
        </Button>
      </Stack>
      <Modal
        open={modalIsOpen}
        handleClose={() => {
          setModalContent(null)
          setModalIsOpen(false)
        }}
      >
        {modalContent ?? 'null'}
      </Modal>
    </Box>
  )
}

function ProcessingContent (props: { status: ResponseStatus }): React.JSX.Element {
  const { status } = props
  const { palette } = useTheme()

  return (
    <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
      {
        status.state === 'pending'
          ? <CircularProgress sx={{ color: palette.accent.main }}/>
          : status.state === 'success'
            ? <CheckIcon sx={{ color: palette.accent.main }} />
            : <ErrorIcon sx={{ color: palette.accent.main }} />
      }
      {
        status.state === 'failure' && <Typography sx={{ color: palette.error.main }}>{status.message}</Typography>
      }
    </Stack>
  )
}

export function ExportModalContent (): React.JSX.Element {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [status, setStatus] = useState<ResponseStatus>({
    state: 'pending',
    message: ''
  })

  function startDownload (
    aggregationType: 'none' | 'day' | 'transaction',
    includeFees: boolean,
    endTime?: Dayjs,
    startTime?: Dayjs,
    includedDesks?: readonly string[],
    includedStrategies?: readonly string[],
    includedBaseFxs?: readonly string[],
    excludedBaseFxs?: readonly string[]
  ): void {
    setIsProcessing(true)
    getBlotterExport(
      aggregationType,
      includeFees,
      endTime,
      startTime,
      includedDesks,
      includedStrategies,
      includedBaseFxs,
      excludedBaseFxs
    )
      .then((value) => {
        if (value.success as boolean) {
          setStatus({
            state: 'success',
            message: ''
          })
        } else {
          setStatus({
            state: 'failure',
            message: value.message
          })
        }
      })
      .catch(() => {
        setStatus({
          state: 'failure',
          message: 'Unexpected Error'
        })
      })
  }

  return (
    <Box sx={{ width: '40vw', margin: '1vw' }}>
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2}>
        {
          isProcessing
            ? <ProcessingContent status={status}/>
            : <EntryContent startDownload={startDownload}/>
        }
      </Stack>
    </Box>
  )
}

export default ExportModalContent
