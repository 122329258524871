import React from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'

import { getErrorMessage } from '../../utils/errors'

function TableErrorBody (props: {
  colsToSpan: number
  error: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    colsToSpan,
    error
  } = props
  const { palette } = useTheme()

  const errorString = getErrorMessage(error)
  return (
    <TableBody>
      <TableRow sx={{ height: '100%' }}>
        <TableCell
          colSpan={colsToSpan}
          align='center'
        >
          <Typography
            sx={{
              fontSize: 20,
              color: palette.error.main
            }}
          >Error: {errorString}</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default TableErrorBody
