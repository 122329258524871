import React from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  Grid,
  Box,
  type SelectChangeEvent,
  Stack,
  useTheme,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { RefreshButton } from '@r40cap/ui'
import { useAuth } from '@r40cap/auth'

import ViewSelection from './ViewSelection'
import {
  type SingleReferenceTimeOption,
  SingleReferenceTimeType,
  PortfolioViewType,
  type BaseFxOption
} from './types'
import { singleReferenceTimingOptions } from './constants'
import BaseFxSelection from './BaseFxSelection'
import DeskSelection from '../common/DeskSelection'
import { getMarketCloseHour } from '../../utils/times'
import { type DeskOption } from '../common/types'
import { getErrorMessage } from '../../utils/errors'

function SingleReferenceTimingSelction (props: {
  timing: SingleReferenceTimeOption
  timingChangeFunction: (option: SingleReferenceTimeOption) => void
}): React.JSX.Element {
  const { timing, timingChangeFunction } = props

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const chosenOption = singleReferenceTimingOptions.find((option) => option.value === event.target.value)
    if (chosenOption !== undefined) {
      timingChangeFunction(chosenOption)
    }
  }

  return (
    <Select
      value={timing.value}
      onChange={handleTypeChange}
      sx={{ width: '100%', height: '100%' }}
      color="accent"
    >
      {singleReferenceTimingOptions.map(
        (option, index) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  )
}

function EODPicker (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
}): React.JSX.Element {
  const { time, timeChangeFunction } = props
  dayjs.extend(utc)
  dayjs.extend(timezone)

  const styles = {
    datePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  const handleChangeDate = (newDate: Dayjs | null): void => {
    if (newDate !== null) {
      const day = newDate.format('YYYY-MM-DD')
      const marketCloseHour = getMarketCloseHour(newDate)
      const easternTime = dayjs.tz(`${day} ${marketCloseHour}:00:00`, 'America/New_York')
      const utcTime = easternTime.utc()
      timeChangeFunction(utcTime)
    } else {
      timeChangeFunction(null)
    }
  }

  return (
    <Box sx={styles.datePickerContainer}>
      <DatePicker
        value={time}
        onChange={(newValue) => { handleChangeDate(newValue) }}
      />
    </Box>
  )
}

function PositionsSelctions (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
  timing: SingleReferenceTimeOption
  timingChangeFunction: (option: SingleReferenceTimeOption) => void
  includeUnsettled: boolean
  includeUnsettledChangeFunction: (value: boolean) => void
  includeSmallAmounts: boolean
  includeSmallAmountsChangeFunction: (value: boolean) => void
  showIncludeSmallSwitch: boolean
}): React.JSX.Element {
  const {
    time,
    timeChangeFunction,
    timing,
    timingChangeFunction,
    includeUnsettled,
    includeUnsettledChangeFunction,
    includeSmallAmounts,
    includeSmallAmountsChangeFunction,
    showIncludeSmallSwitch
  } = props
  const { palette } = useTheme()

  return <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{ height: '100%', width: '100%' }}>
    <Box sx={{ width: '33%', height: '100%' }}>
      <SingleReferenceTimingSelction
        timing={timing}
        timingChangeFunction={timingChangeFunction}
      />
    </Box>
    {
      timing.type === SingleReferenceTimeType.EOD && <Box sx={{ width: '33%', height: '100%' }}>
          <EODPicker
            time={time}
            timeChangeFunction={timeChangeFunction}
          />
        </Box>
    }
    <Stack
      direction={'column'}
      spacing={0}
      alignItems={'start'}
      justifyContent={'center'}
      sx={{ width: '34%', height: '100%' }}
    >
      <FormControlLabel
        control={<Switch
          size='small'
          sx={{
            '& .Mui-checked+.MuiSwitch-track': {
              backgroundColor: palette.accent.main,
              opacity: 0.9
            },
            '& .MuiSwitch-thumb': {
              color: palette.primary.main
            },
            '& .MuiSwitch-track': {
              backgroundColor: palette.tableBodyText.main
            }
          }}
          color='default'
          checked={includeUnsettled}
          onChange={() => { includeUnsettledChangeFunction(!includeUnsettled) }}
        />}
        label={<Typography
          sx={{ color: palette.tableBodyText.main, fontSize: 12 }}
        >Include Unsettled?</Typography>}
      />
      {
        showIncludeSmallSwitch && <FormControlLabel
          control={<Switch
            size='small'
            sx={{
              '& .Mui-checked+.MuiSwitch-track': {
                backgroundColor: palette.accent.main,
                opacity: 0.9
              },
              '& .MuiSwitch-thumb': {
                color: palette.primary.main
              },
              '& .MuiSwitch-track': {
                backgroundColor: palette.tableBodyText.main
              }
            }}
            color='default'
            checked={includeSmallAmounts}
            onChange={() => { includeSmallAmountsChangeFunction(!includeSmallAmounts) }}
          />}
          label={<Typography
            sx={{ color: palette.tableBodyText.main, fontSize: 12 }}
          >Include Small?</Typography>}
        />
      }
    </Stack>
  </Stack>
}

function LiabilitySelections (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
  timing: SingleReferenceTimeOption
  timingChangeFunction: (option: SingleReferenceTimeOption) => void
  includeUnsettled: boolean
  includeUnsettledChangeFunction: (value: boolean) => void
  includeOwed: boolean
  includeOwedChangeFunction: (value: boolean) => void
}): React.JSX.Element {
  const {
    time,
    timeChangeFunction,
    timing,
    timingChangeFunction,
    includeUnsettled,
    includeUnsettledChangeFunction,
    includeOwed,
    includeOwedChangeFunction
  } = props
  const { palette } = useTheme()

  return <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{ width: '100%', height: '100%' }}>
    <Box sx={{ width: '33%', height: '100%' }}>
      <SingleReferenceTimingSelction
        timing={timing}
        timingChangeFunction={timingChangeFunction}
      />
    </Box>
    {
      timing.type === SingleReferenceTimeType.EOD && <Box sx={{ width: '33%', height: '100%' }}>
          <EODPicker
          time={time}
          timeChangeFunction={timeChangeFunction}
        />
      </Box>
    }
    <Stack
      direction={'column'}
      spacing={0}
      alignItems={'start'}
      justifyContent={'center'}
      sx={{ width: '34%', height: '100%' }}
    >
      <FormControlLabel
        control={<Switch
          size='small'
          sx={{
            '& .Mui-checked+.MuiSwitch-track': {
              backgroundColor: palette.accent.main,
              opacity: 0.9
            },
            '& .MuiSwitch-thumb': {
              color: palette.primary.main
            },
            '& .MuiSwitch-track': {
              backgroundColor: palette.tableBodyText.main
            }
          }}
          color='default'
          checked={includeUnsettled}
          onChange={() => { includeUnsettledChangeFunction(!includeUnsettled) }}
        />}
        label={<Typography
          sx={{ color: palette.tableBodyText.main, fontSize: 12 }}
        >Include Unsettled?</Typography>}
      />
      <FormControlLabel
          control={<Switch
            size='small'
            sx={{
              '& .Mui-checked+.MuiSwitch-track': {
                backgroundColor: palette.accent.main,
                opacity: 0.9
              },
              '& .MuiSwitch-thumb': {
                color: palette.primary.main
              },
              '& .MuiSwitch-track': {
                backgroundColor: palette.tableBodyText.main
              }
            }}
            color='default'
            checked={includeOwed}
            onChange={() => { includeOwedChangeFunction(!includeOwed) }}
          />}
          label={<Typography
            sx={{ color: palette.tableBodyText.main, fontSize: 12 }}
          >Include Owed?</Typography>}
        />
    </Stack>
  </Stack>
}

function UnsettledSelctions (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
  timing: SingleReferenceTimeOption
  timingChangeFunction: (option: SingleReferenceTimeOption) => void
}): React.JSX.Element {
  const {
    time,
    timeChangeFunction,
    timing,
    timingChangeFunction
  } = props

  return <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{ height: '100%', width: '100%' }}>
    <Box sx={{ width: '33%', height: '100%' }}>
      <SingleReferenceTimingSelction
        timing={timing}
        timingChangeFunction={timingChangeFunction}
      />
    </Box>
    {
      timing.type === SingleReferenceTimeType.EOD && <Box sx={{ width: '33%', height: '100%' }}>
        <EODPicker
          time={time}
          timeChangeFunction={timeChangeFunction}
        />
      </Box>
    }
  </Stack>
}

function PortfolioControlsPanel (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
  viewType: PortfolioViewType
  viewTypeChangeFunction: (option: PortfolioViewType) => void
  singleReferenceTiming: SingleReferenceTimeOption
  singleReferenceTimingChangeFunction: (option: SingleReferenceTimeOption) => void
  includeUnsettled: boolean
  includeUnsettledChangeFunction: (value: boolean) => void
  includeOwed: boolean
  includeOwedChangeFunction: (value: boolean) => void
  includeSmallAmounts: boolean
  includeSmallAmountsChangeFunction: (value: boolean) => void
  refreshFunction: () => void
  base: BaseFxOption
  setBase: (value: BaseFxOption) => void
  desk: DeskOption
  setDesk: (value: DeskOption) => void
  pxIsError: boolean
  pxError: FetchBaseQueryError | SerializedError | undefined
}): React.JSX.Element {
  const {
    time,
    timeChangeFunction,
    viewType,
    viewTypeChangeFunction,
    singleReferenceTiming,
    singleReferenceTimingChangeFunction,
    includeUnsettled,
    includeUnsettledChangeFunction,
    includeOwed,
    includeOwedChangeFunction,
    includeSmallAmounts,
    includeSmallAmountsChangeFunction,
    refreshFunction,
    base,
    setBase,
    desk,
    setDesk,
    pxIsError,
    pxError
  } = props
  const { palette } = useTheme()
  const authConext = useAuth()

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={6} sx={{ height: '100%' }}>
        <Stack direction={'row'} spacing={1} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ height: '100%', width: '15%' }}>
            <ViewSelection
              currentView={viewType}
              viewChangeFunction={viewTypeChangeFunction}
            />
          </Box>
          <Box sx={{ height: '100%', width: '75%' }}>
            {
              (
                viewType === PortfolioViewType.POSITIONS ||
                viewType === PortfolioViewType.BALANCES
              ) && <PositionsSelctions
                time={time}
                timeChangeFunction={timeChangeFunction}
                timing={singleReferenceTiming}
                timingChangeFunction={singleReferenceTimingChangeFunction}
                includeUnsettled={includeUnsettled}
                includeUnsettledChangeFunction={includeUnsettledChangeFunction}
                includeSmallAmounts={includeSmallAmounts}
                includeSmallAmountsChangeFunction={includeSmallAmountsChangeFunction}
                showIncludeSmallSwitch={true}
              />
            }
            {
              (viewType === PortfolioViewType.UNSETTLED) && <UnsettledSelctions
                time={time}
                timeChangeFunction={timeChangeFunction}
                timing={singleReferenceTiming}
                timingChangeFunction={singleReferenceTimingChangeFunction}
              />
            }
            {viewType === PortfolioViewType.LIABILITIES && <LiabilitySelections
              time={time}
              timeChangeFunction={timeChangeFunction}
              timing={singleReferenceTiming}
              timingChangeFunction={singleReferenceTimingChangeFunction}
              includeUnsettled={includeUnsettled}
              includeUnsettledChangeFunction={includeUnsettledChangeFunction}
              includeOwed={includeOwed}
              includeOwedChangeFunction={includeOwedChangeFunction}
            />}
          </Box>
          <Box sx={{ height: '100%', width: '18%' }}>
            {
              viewType === PortfolioViewType.POSITIONS && authConext.restrictedDeskId === undefined && <DeskSelection
                currentDesk={desk}
                deskChange={setDesk}
              />
            }
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={3}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
        sx={{ height: '100%' }}
      >
        {
          pxIsError && <Typography
            sx={{
              color: palette.error.main,
              fontSize: '1rem'
            }}
          >
            Failed to get Prices: {getErrorMessage(pxError)}
          </Typography>
        }
      </Grid>
      <Grid
        item
        xs={3}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ height: '100%' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            <BaseFxSelection
              currentBase={base}
              baseChange={setBase}
            />
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PortfolioControlsPanel
