export enum HistoryInputType {
  NUMBER,
  TEXT,
  USER,
  TIME
}

export interface HistoryRow {
  id: string
  entryTime: string
  systemPositionsTime: string
  user: string
  account: string
  instrument: string
  systemBalance: number
  platformBalance: number
  platformErrorExplanation: string
  adminBalance: number
  adminErrorExplanation: string
  balanceDecimals: number
}
