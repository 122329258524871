import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import { Outlet } from 'react-router-dom'
import {
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  useTheme
} from '@mui/material'
import FunctionsIcon from '@mui/icons-material/Functions'
import { HeaderCell, type Order, RowSkeleton } from '@r40cap/ui'
import {
  type Price,
  portfolioApi,
  glossaryApi
} from '@r40cap/pms-sdk'

import BalancesByAccountTableBody from './BalancesByAccountTableBody'
import { modifiedAccountColumns } from './constants'
import type { BalanceWithPrice, ColumnDefinitionWithStaleness, AccountRowObject } from './types'
import { ValueCellWithColor } from './rows/cells'

function BalancesTableHead (props: {
  onRequestSort: (property: keyof AccountRowObject) => void
  order: Order
  orderBy: keyof AccountRowObject
  columns: Array<ColumnDefinitionWithStaleness<AccountRowObject, any>>
  openAll: () => void
  closeAll: () => void
  openDesc: string
  isLoading: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    onRequestSort,
    order,
    orderBy,
    columns,
    openAll,
    closeAll,
    openDesc,
    isLoading
  } = props
  const [clickTracker, setClickTracker] = useState<number>(0)
  const [buttonText, setButtonText] = useState<string>(openDesc)

  const createSortHandler = (property: keyof AccountRowObject): void => {
    onRequestSort(property)
  }

  function handleToggle (): void {
    if (clickTracker === 0) {
      openAll()
      setClickTracker(1)
      setButtonText('Close')
    } else {
      closeAll()
      setClickTracker(0)
      setButtonText(openDesc)
    }
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: palette.tertiary.main, padding: 1 }}>
          <Button
            variant='outlined'
            disabled={isLoading}
            onClick={handleToggle}
            sx={{
              color: palette.accent.main,
              fontSize: 9
            }}
          >
            {buttonText}
          </Button>
        </TableCell>
        {columns.map((column, idx) => (
          <HeaderCell<AccountRowObject, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={column.id}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
            dense
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

function BalancesByAccountTableFooter (props: {
  totalObject: AccountRowObject
  isLoading: boolean
}): React.JSX.Element {
  const { totalObject, isLoading } = props
  const { palette } = useTheme()

  if (
    isLoading ||
    totalObject === undefined
  ) {
    return (
      <TableFooter sx={{ position: 'sticky', bottom: 0 }}>
        <RowSkeleton
          usedKey={-1}
          columns={modifiedAccountColumns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
        />
      </TableFooter>
    )
  } else {
    return (
      <TableFooter sx={{ position: 'sticky', bottom: 0 }}>
        <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
          <TableCell>
            <FunctionsIcon />
          </TableCell>
          {modifiedAccountColumns.map((column, idx) => (
            <ValueCellWithColor<AccountRowObject, any>
              column={column}
              item={totalObject}
              key={column.id}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
            />
          ))}
        </TableRow>
      </TableFooter>
    )
  }
}

function BalancesByAccountTable (props: {
  time?: Dayjs
  includeUnsettled: boolean
  refreshSignal: boolean
  includeSmallAmounts: boolean
  smallThreshold: number
  pxData?: Price[]
  pxIsLoading: boolean
  pxIsError: boolean
}): React.JSX.Element {
  dayjs.extend(minMax)
  dayjs.extend(utc)
  const { palette } = useTheme()
  const {
    time,
    includeUnsettled,
    refreshSignal,
    includeSmallAmounts,
    smallThreshold,
    pxData,
    pxIsLoading,
    pxIsError
  } = props
  const [openedList, setOpenedList] = useState<readonly string[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof AccountRowObject>('grossPositionsValue')
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryParams, setQueryParams] = useState({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
    includeUnsettled,
    force: false
  })
  const [combinedData, setCombinedData] = useState<BalanceWithPrice[]>([])
  const [accountToRiskEngineSlug, setAccountToRiskEngineSlug] = useState<Map<string, string>>(new Map<string, string>())

  const {
    data: balData,
    refetch: balRefetch,
    isFetching: balIsFetching,
    isError: balIsError,
    error: balError
  } = portfolioApi.useGetBalancesByAccountQuery(queryParams, {
    skip: isInitialLoad
  })
  const { data: accountsData } = glossaryApi.useGetAccountsQuery({})

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setQueryParams({
      time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
      includeUnsettled,
      force: false
    })
  }, [time, includeUnsettled])

  useEffect(() => {
    if (!isInitialLoad) {
      if (!queryParams.force) {
        setQueryParams((prevParams) => ({
          ...prevParams,
          force: true
        }))
      } else {
        balRefetch().catch(() => {
          console.error('Error Refreshing')
        })
      }
    }
  }, [refreshSignal])

  useEffect(() => {
    if (balData !== null && balData !== undefined) {
      const combined = balData.data.map(bal => {
        const effectivePxData = pxIsLoading ? [] : pxData ?? []
        const priceData = effectivePxData.find(px => px.instrument.id === bal.instrumentId)
        return {
          ...bal,
          price: priceData?.price,
          beta: priceData?.beta,
          delta: priceData?.delta,
          priceTime: priceData?.time
        }
      })
      setCombinedData(combined)
    }
  }, [balData, pxData])

  useEffect(() => {
    const newAccMap = new Map<string, string>()
    accountsData?.data.forEach((acc) => {
      if (acc.riskEngineSlug !== undefined && acc.riskEngineSlug !== null) {
        newAccMap.set(acc.id, acc.riskEngineSlug)
      }
    })
    setAccountToRiskEngineSlug(newAccMap)
  }, [accountsData])

  const handleRequestSort = (property: keyof AccountRowObject): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: palette.primary.main,
          height: '100%',
          borderRadius: '5px'
        }}
      >
        <Table stickyHeader sx={{ height: '100%' }}>
          <BalancesTableHead
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            columns={modifiedAccountColumns}
            closeAll={() => {
              setOpenedList([])
            }}
            openAll={() => {
              const accSet = new Set<string>()
              combinedData.forEach((val) => accSet.add(val.accountId))
              setOpenedList(Array.from(accSet))
            }}
            openDesc='Open Accounts'
            isLoading={balIsFetching}
          />
          <BalancesByAccountTableBody
            isLoading={balIsFetching}
            positions={combinedData}
            order={order}
            orderBy={orderBy}
            columns={modifiedAccountColumns}
            includeSmallAmounts={includeSmallAmounts}
            smallThreshold={(pxIsError || pxIsLoading) ? 0 : smallThreshold}
            requestedTime={time}
            openedList={openedList}
            toggleAccount={(accountId) => {
              if (openedList.includes(accountId)) {
                setOpenedList(openedList.filter((val) => val !== accountId))
              } else {
                setOpenedList([...openedList, accountId])
              }
            }}
            accountIdToRiskEngineSlug={accountToRiskEngineSlug}
            showPlatformOption={time === undefined}
            isError={balIsError}
            error={balError}
          />
          <BalancesByAccountTableFooter
            isLoading={balIsFetching}
            totalObject={{
              id: 'Total',
              accountDisplay: 'Fund Total',
              oldestCashPriceTime: dayjs.min(combinedData.filter((bal) => (bal.instrumentIsCash && bal.instrumentIsAsset)).map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc(),
              cashValue: combinedData.reduce((partialSum, a) => partialSum + (a.instrumentIsCash ? (a?.price ?? 0) * a.quantity * a.multiplier : 0), 0),
              oldestAssetPriceTime: dayjs.min(combinedData.filter((bal) => bal.instrumentIsAsset).map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc(),
              assetsValue: combinedData.reduce((partialSum, a) => partialSum + (a.instrumentIsAsset ? (a?.price ?? 0) * a.quantity * a.multiplier : 0), 0),
              oldestPositionPriceTime: dayjs.min(combinedData.filter((bal) => !bal.instrumentIsAsset).map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc(),
              grossPositionsValue: combinedData.reduce((partialSum, a) => partialSum + Math.abs(a.instrumentIsAsset ? 0 : (a?.price ?? 0) * a.quantity * a.multiplier), 0)
            }}
          />
        </Table>
      </TableContainer>
      <Outlet />
    </>
  )
}

export default BalancesByAccountTable
