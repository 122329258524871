import React, { useState } from 'react'
import { Box, Button, Stack, useTheme } from '@mui/material'
import {
  BaseFxInput,
  type LiteBaseFx
} from '@r40cap/pms-sdk'

import type { FilterPiece } from '../../common/types'

function BaseFxFilterContent (props: {
  submitFunction: (value: FilterPiece) => void
}): React.JSX.Element {
  const { submitFunction } = props
  const [choice, setChoice] = useState<FilterPiece | null>(null)
  const { palette } = useTheme()

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <BaseFxInput
          setBaseFx={(baseFx: LiteBaseFx | null) => {
            if (baseFx !== null) {
              setChoice({ id: baseFx.id, name: baseFx.ticker })
            } else {
              setChoice(null)
            }
          }}
          allowNone={false}
          setNone={() => {}}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={false}
        />
        <Button
          variant='contained'
          onClick={() => {
            if (choice !== null) {
              submitFunction(choice)
            }
          }}
          disabled={choice === null}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export default BaseFxFilterContent
