import React from 'react'

import { HistoryInputType } from './types'
import {
  NumberEditContent,
  StringEditContent,
  TimeEditContent,
  TraderEditContent
} from '../modals'

export function getEditModalContent (
  inputType: HistoryInputType,
  label: string,
  closeModal: () => void,
  handleSubmission: (value: any) => void,
  setSelected: (value: string[]) => void,
  currentValue: any
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value)
    setSelected([])
  }

  switch (inputType) {
    case HistoryInputType.TEXT:
      content = <StringEditContent
          title={label}
          closeModal={closeModal}
          submit={submit}
          prefillValue={currentValue}
        />
      break
    case HistoryInputType.USER:
      content = <TraderEditContent
          closeModal={closeModal}
          submit={submit}
          prefillTrader={currentValue}
        />
      break
    case HistoryInputType.TIME:
      content = <TimeEditContent
          closeModal={closeModal}
          submit={submit}
        />
      break
    default:
      content = <NumberEditContent
          closeModal={closeModal}
          submit={submit}
          title={label}
          prefillValue={currentValue}
        />
  }
  return content
}
