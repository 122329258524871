import React from 'react'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { useRequestSnackbar } from '@r40cap/ui'
import { marginAlertsApi } from '@r40cap/alerts-sdk'

import { isApiError } from '../../../utils/errors'

function DeleteMarginAlertModalContent (props: {
  marginAlertId: string
  closeModal: () => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const { marginAlertId, closeModal } = props
  const { showSnackbar } = useRequestSnackbar()
  const [performDelete] = marginAlertsApi.useDeleteMarginAlertMutation()

  function deleteAlert (): void {
    if (marginAlertId !== undefined) {
      showSnackbar({
        isOpen: true,
        message: 'Deleting Alert',
        status: 'processing'
      })
      performDelete({ marginAlertId })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Deleted Alert',
              status: 'success'
            })
            closeModal()
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to delete Alert',
            status: 'error'
          })
        })
    }
  }

  return (
    <Box
      sx={{
        width: '25vw',
        padding: '20px'
      }}
    >
      <Stack direction={'column'} spacing={2}>
        <Typography
          variant='h2'
          sx={{ color: palette.tableBodyText.main }}
        >
          Delete Alert?
        </Typography>
        <Typography
          variant='h5'
          sx={{ color: palette.tableBodyText.main }}
        >
          This action cannot be undone.
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <Button
            variant='outlined'
            onClick={closeModal}
            sx={{
              outlineColor: palette.accent.main,
              color: palette.accent.main,
              width: '100%'
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={deleteAlert}
            style={{
              width: '100%',
              backgroundColor: palette.accent.main,
              color: palette.secondary.main
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default DeleteMarginAlertModalContent
