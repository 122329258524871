import React from 'react'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import DangerousIcon from '@mui/icons-material/Dangerous'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { usePermissions as useAlertPermissions } from '@r40cap/alerts-sdk'
import AccountMarginAlertsDisplay from './alerts/AccountMarginAlertsDisplay'

function HealthScoreDisplay (props: {
  accountId: string
  score: number | null
  isFetching: boolean
  isEdited: boolean
  isError: boolean
}): React.JSX.Element {
  const {
    accountId,
    score,
    isFetching,
    isEdited,
    isError
  } = props
  const { palette } = useTheme()
  const alertPermissions = useAlertPermissions()

  const textColor = score === null || isFetching
    ? palette.tableBodyText.main
    : score >= 3.5
      ? palette.goodHealthColor.main
      : score >= 1.1
        ? palette.dangerHealthColor.main
        : palette.badHealthColor.main

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: palette.tertiary.main,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        borderColor: isEdited ? palette.accent.main : undefined,
        borderWidth: isEdited ? '1px' : undefined,
        borderStyle: isEdited ? 'solid' : undefined
      }}
    >
      {
        (alertPermissions.hasMarginAlertsAccess ?? false) && <Box
          sx={{
            maxWidth: '50%',
            width: '180px',
            padding: '5px'
          }}
          position={'absolute'}
          top={'0'}
          left={'0'}
        >
          <AccountMarginAlertsDisplay accountId={accountId} />
        </Box>
      }
      {
        isError
          ? <Tooltip
              title='Error fetching health score'
              placement='bottom'
            >
            <DangerousIcon
              sx={{
                fontSize: '3.5rem',
                color: palette.error.main,
                alignContent: 'center'
              }}
            />
          </Tooltip>
          : score === null || isFetching
            ? <HorizontalRuleIcon
                sx={{
                  fontSize: '3.5rem',
                  color: textColor,
                  alignContent: 'center'
                }}
              />
            : score === Infinity
              ? <AllInclusiveIcon
                  sx={{
                    fontSize: '3.5rem',
                    color: textColor,
                    alignContent: 'center'
                  }}
                />
              : (
                <Box sx={{ textAlign: 'center', position: 'relative' }}>
                  <Typography
                    sx={{
                      fontSize: '3.5rem',
                      fontWeight: 'bold',
                      color: textColor
                    }}
                  >
                    {score.toLocaleString('en-US', { maximumFractionDigits: 5 })}
                  </Typography>
                </Box>
                )
      }
    </Box>
  )
}

export default HealthScoreDisplay
