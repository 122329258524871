import React from 'react'
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import type { FilterPiece } from '../../common/types'

function FilterDescription (props: {
  name: string
  values: FilterPiece[]
  clearFunction: () => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    name,
    values,
    clearFunction
  } = props

  return (
    <Box
      sx={{
        backgroundColor: palette.background.default,
        borderRadius: '5px',
        alignContent: 'center'
      }}
    >
      <Stack
        direction={'row'}
        sx={{ alignItems: 'center' }}
      >
        <Typography sx={{ fontSize: 10, padding: 1, color: palette.tableHeaderText.main }}>
          {name} in [{values.map((value) => value.name).join(', ')}]
        </Typography>
        <IconButton
          onClick={clearFunction}
          sx={{ padding: 1, color: 'red' }}
        >
          <ClearIcon fontSize='small'/>
        </IconButton>
      </Stack>
    </Box>
  )
}

export default FilterDescription
