import React, { useEffect, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Stack } from '@mui/material'
import { priceApi } from '@r40cap/pms-sdk'
import { useAuth } from '@r40cap/auth'

import Page from './Page'
import { getLastMark } from '../utils/times'
import PortfolioControlsPanel from '../components/portfolio/PortfolioControlsPanel'
import {
  type BaseFxOption,
  PortfolioViewType,
  SingleReferenceTimeType,
  type SingleReferenceTimeOption
} from '../components/portfolio/types'
import {
  baseFxOptions,
  singleReferenceTimingOptions
} from '../components/portfolio/constants'
import {
  LiabilitiesByCounterpartyTable,
  PositionsByStrategyTable,
  UnsettledByAccountTable,
  BalancesByAccountTable
} from '../components/portfolio/tables'
import { deskOptions } from '../components/common/constants'
import RiskModal from '../components/portfolio/risk/RiskModal'
import type { DeskOption } from '../components/common/types'

const DEFAULT_REDIRECT_TYPE = PortfolioViewType.POSITIONS
const PRICE_REFRESH_INTERVAL = 1000 * 60 * 2

function Portfolio (): React.JSX.Element {
  dayjs.extend(utc)
  const location = useLocation()
  const navigate = useNavigate()
  const [isDependencyFetch, setIsDependencyFetch] = useState(false)
  const [time, setTime] = useState<Dayjs | null>(null)
  const [refreshSignal, setRefreshSignal] = useState(false)
  const [includeUnsettled, setIncludeUnsettled] = useState(true)
  const [includeOwed, setIncludeOwed] = useState(false)
  const [includeSmallAmounts, setIncludeSmallAmounts] = useState(false)
  const [singleReferenceTiming, setSingleReferenceTiming] = useState<SingleReferenceTimeOption>(singleReferenceTimingOptions[0])
  const [base, setBase] = useState<BaseFxOption>(baseFxOptions[0])
  const [desk, setDesk] = useState<DeskOption>(deskOptions[0])
  const authContext = useAuth()

  const { data: refPxData, refetch: refPxRefetch } = priceApi.useGetInstrumentPriceQuery({
    instrumentId: base.id,
    time: singleReferenceTiming.type === SingleReferenceTimeType.CURRENT
      ? undefined
      : singleReferenceTiming.type === SingleReferenceTimeType.LAST_MARK
        ? getLastMark().format('YYYY-MM-DD HH:mm')
        : (time?.format('YYYY-MM-DD HH:mm') ?? undefined)
  })
  const {
    data: pxData,
    isFetching: pxIsFetching,
    refetch: pxRefetch,
    isError: pxIsError,
    error: pxError
  } = priceApi.useGetPricesQuery({
    time: singleReferenceTiming.type === SingleReferenceTimeType.CURRENT
      ? undefined
      : singleReferenceTiming.type === SingleReferenceTimeType.LAST_MARK
        ? getLastMark().format('YYYY-MM-DD HH:mm')
        : (time?.format('YYYY-MM-DD HH:mm') ?? undefined)
  })

  const handleRefresh = (): void => {
    setRefreshSignal(!(refreshSignal as boolean))
    refPxRefetch().catch(() => { console.error('Error Refreshing BaseFx Price') })
  }

  useEffect(() => {
    setTime(null)
  }, [singleReferenceTiming])

  const handleRefetch = (): void => {
    setIsDependencyFetch(false)
    void pxRefetch()
  }

  useEffect(() => {
    setIsDependencyFetch(true)
  }, [time, singleReferenceTiming])

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefetch()
    }, PRICE_REFRESH_INTERVAL)
    return () => { clearInterval(interval) }
  }, [pxRefetch, time, singleReferenceTiming])

  const usedRedirect = authContext.userId === '5b5a8f9e-ecc8-4eab-b081-9dfdeee6a157' || authContext.userId === 'e068b3ff-4241-4233-8205-0668e9993267'
    ? PortfolioViewType.BALANCES
    : DEFAULT_REDIRECT_TYPE
  const viewType = (location.pathname.split('/')[2] ?? usedRedirect) as PortfolioViewType

  return (
    <Page>
      <Stack
        spacing={1}
        direction={'column'}
        width={'100%'}
        sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
      >
        <Box sx={{ height: '7%' }}>
          <PortfolioControlsPanel
            time={time}
            timeChangeFunction={setTime}
            viewType={viewType}
            viewTypeChangeFunction={(newType) => { navigate(`/portfolio/${newType}`) }}
            singleReferenceTiming={singleReferenceTiming}
            singleReferenceTimingChangeFunction={setSingleReferenceTiming}
            includeUnsettled={includeUnsettled}
            includeUnsettledChangeFunction={setIncludeUnsettled}
            includeOwed={includeOwed}
            includeOwedChangeFunction={setIncludeOwed}
            includeSmallAmounts={includeSmallAmounts}
            includeSmallAmountsChangeFunction={setIncludeSmallAmounts}
            refreshFunction={handleRefresh}
            base={base}
            setBase={setBase}
            desk={desk}
            setDesk={setDesk}
            pxIsError={pxIsError}
            pxError={pxError}
          />
        </Box>
        <Box sx={{ height: '93%' }}>
          <Routes>
            <Route path='' element={<Navigate to={usedRedirect} replace />}/>
            <Route
              path={PortfolioViewType.POSITIONS}
              element={<PositionsByStrategyTable
                time={
                  singleReferenceTiming.type === SingleReferenceTimeType.CURRENT
                    ? undefined
                    : singleReferenceTiming.type === SingleReferenceTimeType.LAST_MARK
                      ? getLastMark()
                      : (time ?? undefined)
                }
                referencePrice={refPxData?.data}
                includeUnsettled={includeUnsettled}
                smallThreshold={1000}
                includeSmallAmounts={includeSmallAmounts}
                refreshSignal={refreshSignal}
                desk={desk}
                pxData={pxData?.data ?? undefined}
                pxIsLoading={pxIsFetching && isDependencyFetch}
                pxIsError={pxIsError}
              />}
            />
            <Route
              path={PortfolioViewType.BALANCES}
              element={<BalancesByAccountTable
                time={
                  singleReferenceTiming.type === SingleReferenceTimeType.CURRENT
                    ? undefined
                    : singleReferenceTiming.type === SingleReferenceTimeType.LAST_MARK
                      ? getLastMark()
                      : (time ?? undefined)
                }
                includeUnsettled={includeUnsettled}
                refreshSignal={refreshSignal}
                includeSmallAmounts={includeSmallAmounts}
                smallThreshold={1000}
                pxData={pxData?.data ?? undefined}
                pxIsLoading={pxIsFetching && isDependencyFetch}
                pxIsError={pxIsError}
              />}
            >
              <Route path={'risk/*'} element={<RiskModal />} />
            </Route>
            <Route
              path={PortfolioViewType.LIABILITIES}
              element={<LiabilitiesByCounterpartyTable
                time={
                  singleReferenceTiming.type === SingleReferenceTimeType.CURRENT
                    ? undefined
                    : singleReferenceTiming.type === SingleReferenceTimeType.LAST_MARK
                      ? getLastMark()
                      : (time ?? undefined)
                }
                referencePrice={refPxData?.data}
                includeUnsettled={includeUnsettled}
                refreshSignal={refreshSignal}
                includeOwed={includeOwed}
                pxData={pxData?.data ?? undefined}
              />}
            />
            <Route
              path={PortfolioViewType.UNSETTLED}
              element={<UnsettledByAccountTable
                time={
                  singleReferenceTiming.type === SingleReferenceTimeType.CURRENT
                    ? undefined
                    : singleReferenceTiming.type === SingleReferenceTimeType.LAST_MARK
                      ? getLastMark()
                      : (time ?? undefined)
                }
                referencePrice={refPxData?.data}
                refreshSignal={refreshSignal}
                pxData={pxData?.data ?? undefined}
                pxIsLoading={pxIsFetching && isDependencyFetch}
                pxIsError={pxIsError}
              />}
            />
            <Route path='*' element={<Navigate to={usedRedirect} replace />}/>
          </Routes>
        </Box>
      </Stack>
    </Page>
  )
}

export default Portfolio
