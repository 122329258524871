import React, { useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { NumberInput, StringInput } from '@r40cap/ui'
import {
  type LiteTrader,
  TraderInput
} from '@r40cap/pms-sdk'

export function NumberEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
  prefillValue: number
}): React.JSX.Element {
  const { title, submit, closeModal, prefillValue } = props
  const [valueString, setValueString] = useState<string>(`${prefillValue}`)
  const { palette } = useTheme()

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = parseFloat(valueString)
      submit(result)
    }
    closeModal()
  }

  return (
    <Box sx={{ width: '17vw' }}>
      <Stack direction={'column'} spacing={2} padding={3}>
        <NumberInput
          title={title}
          intOnly={false}
          valueString={valueString}
          setValueString={setValueString}
          submit={handleEnterSubmission}
          defaultValue={0}
          defaultPrecision={1}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function StringEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: string) => void
  prefillValue: string
}): React.JSX.Element {
  const { title, submit, closeModal, prefillValue } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<string>(prefillValue)

  function handleSubmit (): void {
    submit(value)
    closeModal()
  }

  return (
    <Box sx={{ width: '40vw' }}>
      <Stack direction={'column'} spacing={2} padding={3}>
        <StringInput
          title={title}
          value={value}
          setValue={setValue}
          enterSubmit={handleSubmit}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmit}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function TraderEditContent (props: {
  closeModal: () => void
  submit: (value: LiteTrader) => void
  prefillTrader: LiteTrader | null
}): React.JSX.Element {
  const { closeModal, submit, prefillTrader } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteTrader | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box sx={{ width: '40vw' }}>
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2} padding={3}>
        <TraderInput
          setTrader={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced
          prefillTraderId={prefillTrader?.id}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function TimeEditContent (props: {
  closeModal: () => void
  submit: (value: Dayjs) => void
}): React.JSX.Element {
  dayjs.extend(utc)
  const { closeModal, submit } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<Dayjs | null>(dayjs().utc())

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setValue(newTime)
    }
  }

  return (
    <Box sx={{ width: '25vw' }}>
      <Stack direction={'column'} spacing={2} padding={3}>
        <DateTimePicker
          value={value}
          format='YYYY-MM-DD HH:mm'
          onChange={handleChangeTime}
          closeOnSelect={false}
          ampm={false}
          timeSteps={{ hours: 1, minutes: 1 }}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}
